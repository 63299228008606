import { useMemo } from 'react';
import map from 'lodash/map';

import { ClassName, GADataType, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { CheckInTasksCacheKeys } from '../../../tasksTypes';
import { BatchCheckInTasksWithoutEventsModalButtonTasks } from './BatchCheckInTasksWithoutEventsModalButton.types';

import {
  FETCH_FIN_SELECT_COMPANY_EVENT_TEMPLATES_QUERY,
  FetchFinSelectCompanyEventTemplateQueryResponse
} from '../../../../companyEventTemplates/queries/fetchFinSelectCompanyEventTemplates.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useFinPaginatedCompanyEventTemplates } from '../../../../companyEventTemplates/hooks/useFinPaginatedCompanyEventTemplates';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { BatchCreateSmartContractSharesModalButton } from '../../../../smartContractShares/components/modalButtons/BatchCreateSmartContractSharesModalButton';

import { CompanyEventTemplateCache } from '../../../../companyEventTemplates/CompanyEventTemplateCache';

import { tasksKeys } from '../../../../../locales/keys';

interface BatchCheckInTasksWithoutEventsModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tasks: BatchCheckInTasksWithoutEventsModalButtonTasks;
  cacheKeys?: CheckInTasksCacheKeys;
  tooltipI18nText?: I18nText;
  afterCreate?: () => void;
}

function BatchCheckInTasksWithoutEventsModalButton({
  afterCreate,
  cacheKeys,
  className,
  dataGa,
  i18nText,
  icon,
  iconClassName,
  tasks,
  tooltipI18nText
}: BatchCheckInTasksWithoutEventsModalButtonProps & GADataType) {
  const currentUser = useCurrentUser();

  const {
    companyEventTemplates,
    companyEventTemplatesErrorMessage,
    companyEventTemplatesFetched
  } = useFinPaginatedCompanyEventTemplates<FetchFinSelectCompanyEventTemplateQueryResponse>(
    {
      cacheKey: CompanyEventTemplateCache.selectCompanyCacheKey(
        currentUser.currentTeam?.nanoId
      ),
      query: FETCH_FIN_SELECT_COMPANY_EVENT_TEMPLATES_QUERY,
      initialFilters: {
        companyNanoId: {
          eq: currentUser.currentTeam?.nanoId
        },
        disabledAt: {
          isNull: true
        }
      },
      options: {
        withoutPrefetch: true,
        enabled: !!currentUser.currentTeam?.nanoId,
        enabledPlaceholder: false
      }
    }
  );

  useShowToastOnErrorChange({
    error: companyEventTemplatesErrorMessage
  });

  const withoutEventIds = useMemo(
    () => map(companyEventTemplates, 'id'),
    [companyEventTemplates]
  );

  return (
    <BatchCreateSmartContractSharesModalButton
      afterCreate={afterCreate}
      cacheKeys={cacheKeys}
      className={className}
      dataGa={dataGa}
      defaultUsers={[currentUser]}
      i18nText={i18nText}
      i18nTitle={tasksKeys.buttons.checkIn}
      icon={icon}
      iconClassName={iconClassName}
      tasks={tasks}
      tooltipI18nText={tooltipI18nText}
      disabled={!companyEventTemplatesFetched}
      withoutEventIds={withoutEventIds}
    />
  );
}

export default BatchCheckInTasksWithoutEventsModalButton;
