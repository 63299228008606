import { gql } from 'graphql-request';

export const BATCH_CREATE_SMART_CONTRACT_SHARES_QUERY = gql`
  mutation BatchCreateSmartContractShares(
    $iteration: Integer!
    $share: Float!
    $shareType: String!
    $taskIds: [ID!]!
    $generalLedgerId: ID!
    $finalAt: DateTime
    $status: String!
    $withoutEventIds: [ID!]
  ) {
    batchCreateSmartContractShares(
      input: {
        iteration: $iteration
        share: $share
        shareType: $shareType
        taskIds: $taskIds
        generalLedgerId: $generalLedgerId
        finalAt: $finalAt
        status: $status
        withoutEventIds: $withoutEventIds
      }
    ) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
