import {
  BatchCreateSmartContractSharesGqlQuery,
  BatchCreateSmartContractSharesGqlStatus,
  SmartContractShareID,
  SmartContractShareUUID,
  SmartContractShareIteration,
  SmartContractShareShare,
  SmartContractShareShareType,
  SmartContractShareGqlError,
  SmartContractShareStatus,
  UpdateSmartContractSharesCacheKeys,
  SmartContractShareFinalAt
} from '../../smartContractSharesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';
import { GeneralLedgerID } from '../../../generalLedgers/generalLedgersTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { CompanyEventTemplateID } from '../../../companyEventTemplates/companyEventTemplatesTypes';

interface BatchCreateSmartContractSharesOptions {
  query: BatchCreateSmartContractSharesGqlQuery;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
}

interface BatchCreateSmartContractSharesRecordResponse {
  id: SmartContractShareID;
}

export interface BatchCreateSmartContractSharesResponse {
  batchCreateSmartContractShares: {
    status: BatchCreateSmartContractSharesGqlStatus;
    recordUuid: SmartContractShareUUID;
    record: BatchCreateSmartContractSharesRecordResponse;
    errors: BatchCreateSmartContractSharesError;
  };
}

export interface BatchCreateSmartContractSharesInput {
  iteration: SmartContractShareIteration;
  share: SmartContractShareShare;
  shareType: SmartContractShareShareType;
  taskIds: TaskID[];
  generalLedgerId: GeneralLedgerID;
  status: SmartContractShareStatus;
  finalAt?: SmartContractShareFinalAt;
  withoutEventIds?: CompanyEventTemplateID[];
}

export interface BatchCreateSmartContractSharesError {
  fullMessages: SmartContractShareGqlError;
}

const action = 'batchCreateSmartContractShares';

function useBatchCreateSmartContractShares({
  query,
  cacheKeys
}: BatchCreateSmartContractSharesOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    BatchCreateSmartContractSharesInput,
    BatchCreateSmartContractSharesResponse,
    BatchCreateSmartContractSharesError,
    BatchCreateSmartContractSharesRecordResponse
  >({ action, cacheKeys, query });

  return {
    batchCreateSmartContractSharesData: createQueryData,
    batchCreateSmartContractSharesError: createQueryError,
    batchCreateSmartContractSharesLoading: createQueryLoading,
    batchCreateSmartContractSharesErrorMessage: createQueryErrorMessage,
    batchCreateSmartContractShares: createQuery,
    batchCreateSmartContractSharesReset: createQueryReset
  };
}

export default useBatchCreateSmartContractShares;
