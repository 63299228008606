import { ClassName, GADataType } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { CheckInTasksCacheKeys } from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { BatchCheckInTasksWithoutEventsModalButton } from '../BatchCheckInTasksWithoutEventsModalButton';
import {
  BatchCheckInTasksModalButton,
  BatchCheckInTasksModalButtonTasks
} from '../BatchCheckInTasksModalButton';
import { TooltipDropdownHelper } from '../../../../../helpers/dropdowns/TooltipDropdownHelper';

import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';

import { tasksKeys } from '../../../../../locales/keys';

interface BatchCheckInTasksWithOptionsModalButtonProps {
  firstButtonAction: string;
  secondButtonAction: string;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tasks: BatchCheckInTasksModalButtonTasks;
  cacheKeys?: CheckInTasksCacheKeys;
  afterCreate?: () => void;
}

function BatchCheckInTasksWithOptionsModalButton({
  firstButtonAction,
  secondButtonAction,
  afterCreate,
  cacheKeys,
  className,
  dataGa,
  icon,
  iconClassName,
  tasks
}: BatchCheckInTasksWithOptionsModalButtonProps & GADataType) {
  const currentUser = useCurrentUser();

  const withFirstButton = currentUser.hasPermissions(firstButtonAction);
  const withSecondButton = currentUser.hasPermissions(secondButtonAction);

  if (!withFirstButton && !withSecondButton) {
    return null;
  }

  if (!withFirstButton) {
    return (
      <BatchCheckInTasksWithoutEventsModalButton
        dataGa={dataGa}
        tooltipI18nText={tasksKeys.buttons.checkInWithoutFollowers}
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        cacheKeys={cacheKeys}
        afterCreate={afterCreate}
        tasks={tasks}
      />
    );
  }

  if (!withSecondButton) {
    return (
      <BatchCheckInTasksModalButton
        dataGa={dataGa}
        tooltipI18nText={tasksKeys.buttons.checkInWithFollowers}
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        cacheKeys={cacheKeys}
        afterCreate={afterCreate}
        tasks={tasks}
      />
    );
  }

  return (
    <TooltipDropdownHelper
      buttonClassName={className}
      dataGa={dataGa}
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tasksKeys.buttons.checkIn}
    >
      <BatchCheckInTasksModalButton
        dataGa={`${dataGa}-with-followers`}
        i18nText={tasksKeys.buttons.checkInWithFollowers}
        cacheKeys={cacheKeys}
        className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
        afterCreate={afterCreate}
        tasks={tasks}
      />
      <BatchCheckInTasksWithoutEventsModalButton
        dataGa={`${dataGa}-without-followers`}
        i18nText={tasksKeys.buttons.checkInWithoutFollowers}
        cacheKeys={cacheKeys}
        className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
        afterCreate={afterCreate}
        tasks={tasks}
      />
    </TooltipDropdownHelper>
  );
}

export default BatchCheckInTasksWithOptionsModalButton;
