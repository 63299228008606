import { UserID } from '../../../users/usersTypes';
import { InviteUsersToTasksOptionsGqlQuery, TaskID } from '../../tasksTypes';
import {
  InviteUsersToTasksError,
  InviteUsersToTasksResponse
} from './useInviteUsersToTasks.types';
import { UpdateItemCacheKeys } from '../../../../types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { TaskCache } from '../../TaskCache';

interface InviteUsersToTasksOptions {
  query: InviteUsersToTasksOptionsGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

interface InviteUsersToTasksInput {
  taskIds: TaskID[];
  userIds: UserID[];
}

const action = 'inviteUsersToTasks';

function useInviteUsersToTasks({
  query,
  cacheKeys = [TaskCache.indexCacheKey()]
}: InviteUsersToTasksOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    InviteUsersToTasksInput,
    InviteUsersToTasksResponse,
    InviteUsersToTasksError,
    undefined
  >({ action, cacheKeys, query });

  return {
    inviteUsersToTasks: updateQuery,
    inviteUsersToTasksData: updateQueryData,
    inviteUsersToTasksError: updateQueryError,
    inviteUsersToTasksErrorMessage: updateQueryErrorMessage,
    inviteUsersToTasksLoading: updateQueryLoading,
    inviteUsersToTasksReset: updateQueryReset
  };
}

export default useInviteUsersToTasks;
