import { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import toNumber from 'lodash/toNumber';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { UserClient, UserID } from '../../../../../../users/usersTypes';
import { GeneralLedgerID } from '../../../../../../generalLedgers/generalLedgersTypes';
import { TaskID } from '../../../../../../tasks/tasksTypes';
import { CompanyEventTemplateID } from '../../../../../../companyEventTemplates/companyEventTemplatesTypes';

import {
  SmartContractShareIteration,
  SmartContractShareShareType,
  SmartContractShareShareTypes,
  SmartContractShareStatus,
  SmartContractShareStatuses,
  UpdateSmartContractSharesCacheKeys
} from '../../../../../smartContractSharesTypes';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormShareCreatedStatus,
  CreateSmartContractShareFormView
} from '../../CreateSmartContractShareForm.types';

import { BATCH_CREATE_SMART_CONTRACT_SHARES_QUERY } from '../../../../../queries/batchCreateSmartContractShare.query';
import { INVITE_USERS_TO_TASKS_QUERY } from '../../../../../../tasks/queries/inviteUsersToTasks.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useBatchCreateSmartContractShares } from '../../../../../hooks/useBatchCreateSmartContractShares';
import { useInviteUsersToTasks } from '../../../../../../tasks/hooks/useInviteUsersToTasks';

interface BatchCreateSmartContractSharesFormOptions {
  iteration?: SmartContractShareIteration;
  taskIds: TaskID[];
  status: SmartContractShareStatus;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  defaultUsers?: {
    id: UserID;
    client?: UserClient;
    generalLedgerId?: GeneralLedgerID;
    shareType?: SmartContractShareShareType;
    share?: number;
    createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
    currentTeam?: {
      nanoId: string;
    };
  }[];
  afterCreate?: () => void;
  withoutEventIds?: CompanyEventTemplateID[];
}

const createSmartContractShareFormDefaultValues: CreateSmartContractShareFormData =
  {
    users: {
      clients: [],
      workers: []
    },
    iteration: '',
    withoutEventIds: []
  };

function useBatchCreateSmartContractSharesForm({
  iteration,
  taskIds,
  status,
  cacheKeys,
  defaultUsers,
  afterCreate,
  withoutEventIds
}: BatchCreateSmartContractSharesFormOptions) {
  const isEmptyDefaultUsers = isEmpty(defaultUsers);
  const defaultValues = useMemo(() => {
    const defaultIteration = iteration ? iteration.toString() : '';

    return isEmptyDefaultUsers
      ? {
          ...createSmartContractShareFormDefaultValues,
          iteration: defaultIteration,
          withoutEventIds,
          inviteClientsToSc: false,
          inviteWorkersToSc: true
        }
      : {
          users: {
            clients: map(
              defaultUsers.filter((user) => user.client),
              (user) => ({
                userId: user.id,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                currentTeam: user.currentTeam
              })
            ),
            workers: map(
              defaultUsers.filter((user) => !user.client),
              (user) => ({
                userId: user.id,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                currentTeam: user.currentTeam
              })
            )
          },
          inviteClientsToSc: false,
          inviteWorkersToSc: true,
          iteration: defaultIteration,
          withoutEventIds
        };
  }, [defaultUsers, isEmptyDefaultUsers, iteration, withoutEventIds]);

  const [formView, setFormView] = useState(
    isEmptyDefaultUsers
      ? CreateSmartContractShareFormView.SELECT_USERS
      : CreateSmartContractShareFormView.CHANGE_SHARE
  );

  const [disabledSubmit, setDisabledSubmit] = useState(isEmptyDefaultUsers);
  const [allUsersInviteOnlyToTask, setAllUsersInviteOnlyToTask] =
    useState(false);

  const {
    handleSubmitReactHookForm,
    control,
    resetForm,
    watch,
    setValue,
    getValues
  } = useReactHookForm<CreateSmartContractShareFormData>({
    defaultValues,
    isModalForm: true
  });

  const {
    batchCreateSmartContractShares,
    batchCreateSmartContractSharesLoading,
    batchCreateSmartContractSharesErrorMessage,
    batchCreateSmartContractSharesReset
  } = useBatchCreateSmartContractShares({
    query: BATCH_CREATE_SMART_CONTRACT_SHARES_QUERY,
    cacheKeys
  });

  const {
    inviteUsersToTasks,
    inviteUsersToTasksErrorMessage,
    inviteUsersToTasksLoading,
    inviteUsersToTasksReset
  } = useInviteUsersToTasks({ query: INVITE_USERS_TO_TASKS_QUERY, cacheKeys });

  const handleResetBatchCreateSmartContractSharesForm = useCallback<
    () => void
  >(() => {
    resetForm(defaultValues);
    setFormView(
      isEmptyDefaultUsers
        ? CreateSmartContractShareFormView.SELECT_USERS
        : CreateSmartContractShareFormView.CHANGE_SHARE
    );
    setDisabledSubmit(isEmptyDefaultUsers);
    batchCreateSmartContractSharesReset();
    inviteUsersToTasksReset();
  }, [
    batchCreateSmartContractSharesReset,
    defaultValues,
    inviteUsersToTasksReset,
    isEmptyDefaultUsers,
    resetForm
  ]);

  const goToChangeShareView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.CHANGE_SHARE);
  }, []);

  const goToSelectUsersView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.SELECT_USERS);
  }, []);

  useEffect(() => {
    const subscription = watch((data, { type }) => {
      if (formView === CreateSmartContractShareFormView.SELECT_USERS) {
        const emptyData =
          isEmpty(data.users.clients) && isEmpty(data.users.workers);

        disabledSubmit !== emptyData && setDisabledSubmit(emptyData);
      }

      if (batchCreateSmartContractSharesErrorMessage && type === 'change') {
        batchCreateSmartContractSharesReset();
      }
    });
    return () => subscription.unsubscribe();
  }, [
    batchCreateSmartContractSharesErrorMessage,
    batchCreateSmartContractSharesReset,
    disabledSubmit,
    formView,
    watch
  ]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (
        formView === CreateSmartContractShareFormView.SELECT_USERS &&
        status === SmartContractShareStatuses.INVITED
      ) {
        const {
          users: { workers, clients },
          inviteClientsToSc,
          inviteWorkersToSc
        } = data;

        const isWorkersInviteOnly = !isEmpty(workers) && !inviteWorkersToSc;
        const isClientsInviteOnly = !isEmpty(clients) && !inviteClientsToSc;

        setAllUsersInviteOnlyToTask(
          (!data.inviteClientsToSc && !data.inviteWorkersToSc) ||
            (isEmpty(workers) && isClientsInviteOnly) ||
            (isWorkersInviteOnly && isEmpty(clients))
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [formView, isEmptyDefaultUsers, status, watch]);

  const handleBatchCreateSmartContractShares = handleSubmitReactHookForm({
    onSubmit: async (data: CreateSmartContractShareFormData) => {
      const {
        users: { clients, workers },
        iteration,
        withoutEventIds,
        inviteClientsToSc,
        inviteWorkersToSc
      } = data;

      const allUsersToCreate = compact(
        concat(
          inviteClientsToSc ? clients : null,
          inviteWorkersToSc ? workers : null
        )
      );

      const allUsersToInvite = compact(
        concat(
          inviteClientsToSc ? null : clients,
          inviteWorkersToSc ? null : workers
        )
      );

      const userIds = allUsersToInvite.map((user) => user?.userId);

      const invitingToTask = !isEmpty(userIds)
        ? inviteUsersToTasks({
            taskIds,
            userIds
          })
        : Promise.resolve();

      const creatingShares = allUsersToCreate.map((user) =>
        batchCreateSmartContractShares({
          generalLedgerId: user.generalLedgerId,
          iteration: toNumber(iteration) || 0,
          finalAt: !iteration ? new Date().toISOString() : undefined,
          shareType: user.shareType as SmartContractShareShareTypes,
          share: toNumber(user.share),
          taskIds,
          status,
          withoutEventIds: withoutEventIds
        })
      );

      return Promise.all([invitingToTask, ...creatingShares]).then(() => {
        afterCreate?.();
      });
    },
    dirtyFieldsOnly: false
  });

  return {
    control,
    batchCreateSmartContractSharesLoading,
    inviteUsersToTasksLoading,
    batchCreateSmartContractSharesErrorMessage,
    inviteUsersToTasksErrorMessage,
    batchCreateSmartContractSharesReset,
    handleResetBatchCreateSmartContractSharesForm,
    handleSubmit:
      formView === CreateSmartContractShareFormView.CHANGE_SHARE ||
      allUsersInviteOnlyToTask
        ? handleBatchCreateSmartContractShares
        : goToChangeShareView,
    setValue,
    disabledSubmit,
    formView,
    goToChangeShareView,
    goToSelectUsersView,
    withoutEditUsers: !isEmptyDefaultUsers,
    oneFormView: !isEmptyDefaultUsers || allUsersInviteOnlyToTask,
    getValues
  };
}

export default useBatchCreateSmartContractSharesForm;
