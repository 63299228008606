import { gql } from 'graphql-request';

export const INVITE_USERS_TO_TASKS_QUERY = gql`
  mutation InviteUsersToTasks($taskIds: [ID!]!, $userIds: [ID!]!) {
    inviteUsersToTasks(input: { taskIds: $taskIds, userIds: $userIds }) {
      errors {
        fullMessages
      }
      status
    }
  }
`;
